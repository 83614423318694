import React from "react";
import "./Home.css";
import image1 from "../../assets/images/image1.jpeg";
import image2 from "../../assets/images/image2.jpeg";
import image3 from "../../assets/images/image3.jpeg";
import image4 from "../../assets/images/image4.jpeg";
import video1 from "../../assets/images/video1.mp4";
import video2 from "../../assets/images/video2.mp4";
import video3 from "../../assets/images/video3.mp4";
import video4 from "../../assets/images/video4.mp4";
import person1 from "../../assets/images/person1.jpeg";
import person2 from "../../assets/images/person2.jpeg";
import person3 from "../../assets/images/person3.jpeg";
import person4 from "../../assets/images/person4.jpeg";
import { Wave } from "react-animated-text";
import Logo from "../../assets/images/logo.png";
import Aos from "aos";
import "aos/dist/aos.css";

const Home = () => {
  const [navBar, setNavBar] = React.useState(false);
  React.useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  const changeBackground = () => {
    if (window.scrollY >= 500) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <nav
            className={navBar ? "navbar active col-md-12" : "col-md-12 navbar"}
          >
            <a
              href="/"
              className="logo navbar-brand text-white offset-md-2 ml-3"
            >
              <img
                src={Logo}
                className="img-fluid"
                style={{ width: "150px", height: "50px" }}
                alt="Logo"
              />
            </a>

            <ul className="nav mr-3">
              <li className="nav-item">
                <a href="/" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="#about" className="nav-link">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a href="#work" className="nav-link">
                  Our Work
                </a>
              </li>
              <li className="nav-item">
                <a href="#cta" className="nav-link">
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a href="#testimonials" className="nav-link">
                  Testimonial
                </a>
              </li>
              <li className="nav-item">
                <a href="#contact" className="nav-link">
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>

          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="info" aos-data="fade-in">
                  <h1>
                    <Wave
                      text="Detailing Car Parlour"
                      iterations={1}
                      speed={3}
                    />
                  </h1>
                  <p>
                    <Wave
                      text="Place for all cars in one store"
                      iterations={1}
                      speed={10}
                    />
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="info">
                  <h1>
                    <Wave text="Handled by Experts" iterations={1} speed={5} />
                  </h1>
                  <p>
                    <Wave
                      text="We handle your cars with care and responsibility"
                      iterations={1}
                      speed={20}
                    />
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="info">
                  <h1>
                    <Wave text="Premium Packages" iterations={1} speed={5} />
                  </h1>
                  <p>
                    <Wave
                      text="Combos for every service with great deals"
                      iterations={1}
                      speed={20}
                    />
                  </p>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>

      <section id="about" class="about section-bg">
        <div class="container-fluid px-5 mt-5" data-aos="fade-up">
          <div class="section-title mb-4">
            <h3
              style={{ fontSize: "45px", fontWeight: "600", color: "#37517e" }}
            >
              About Us
            </h3>
            <p>
              Detailing Car Parlour started at the dawn of 2021. Two people from
              different generations had a vision. In less than 2 months, Mansi
              Singh and Krishna Kumar executed their vision and had a running
              business at their hands. One is a leading engineer from IGDTUW and
              other has been running business ventures from the last 20 years.
              Their vision came from their characteristics. Saying Krishna Kumar
              like things clean would be an understatement as he is a neat freak
              since childhood. On the other hand, Mansi has been playing with
              cars ever since she could crawl. So, it's their love and passion
              for automobiles and shining them that started this company.
            </p>
          </div>

          <div class="row">
            <div class="col-lg-6" data-aos="fade-in" data-aos-delay="100">
              <img
                src="https://images.unsplash.com/photo-1517524206127-48bbd363f3d7?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWVjaGFuaWN8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80"
                width="100%"
                height="100%"
                alt=""
                style={{ borderRadius: "20px" }}
              />
            </div>
            <div
              class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <ul>
                <li>
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </i>
                  <div>
                    <h5>What makes us unique?</h5>
                    <p>
                      Vivek Dagar came to our shop on it's inaugural day. He
                      came in his red Maruti Brezza for a car wash. It's been 8
                      months and he has been our customer since that day. We do
                      not believe in serving clients and charging them by the
                      books. We believe in giving a peace of mind to our
                      customers that their vehicles are in great hands. Due to
                      this reason, we are able to make 1000+ customers around
                      Delhi/NCR.
                    </p>
                  </div>
                </li>
                <li>
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </i>
                  <div>
                    <h5>Our Brand Values and Beliefs</h5>
                    <p>
                      ○ We believe in gaining the utmost trust of our customers
                      and we wouldn't trade that for anything.
                    </p>
                    <p>
                      {" "}
                      ○ Happiness of the people that work behind the scenes is
                      just as important to us.{" "}
                    </p>
                    <p>
                      ○ We are hell bent on giving our customers the quality of
                      work they deserve.
                    </p>
                    <p> ○ Our passion drives us to be better every day </p>
                    <p>
                      ○ We are driven towards using products that doesn't harm
                      our environment.{" "}
                    </p>
                    <p>
                      ○ We are keen on becoming the leading Car Wash Service for
                      every person out there in India.
                    </p>
                  </div>
                </li>
              </ul>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                "We take care of the vehicle that takes care of you"
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="cta" class="cta mt-5">
        <div class="container" data-aos="fade-in">
          <div class="row">
            <div class="col-12 text-center text-lg-start">
              <h3>No.1 Car Parlour in Delhi/NCR</h3>
            </div>
          </div>
        </div>
      </section>
      <div className="container" id="work">
        <div className="row">
          <div className="col-12">
            <div class="section-title1 mt-5 mb-4">
              <h3
                style={{
                  fontSize: "45px",
                  fontWeight: "600",
                  color: "#37517e",
                }}
                className="text-center"
              >
                Our Work
              </h3>
            </div>
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-ride="carousel"
              data-aos="fade-in"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src={image1}
                    class="d-block w-100"
                    alt="..."
                    style={{ height: "600px" }}
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src={image2}
                    class="d-block w-100"
                    alt="..."
                    style={{ height: "600px" }}
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src={image3}
                    class="d-block w-100"
                    alt="..."
                    style={{ height: "600px" }}
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src={image4}
                    class="d-block w-100"
                    alt="..."
                    style={{ height: "600px" }}
                  />
                </div>
                <div class="carousel-item">
                  <video
                    autoPlay
                    muted
                    loop
                    class="d-block w-100"
                    style={{ height: "600px" }}
                  >
                    <source src={video1} type="video/mp4" />
                  </video>
                </div>
                <div class="carousel-item">
                  <video
                    autoPlay
                    muted
                    loop
                    class="d-block w-100"
                    style={{ height: "600px" }}
                  >
                    <source src={video2} type="video/mp4" />
                  </video>
                </div>
                <div class="carousel-item">
                  <video
                    autoPlay
                    muted
                    loop
                    class="d-block w-100"
                    style={{ height: "600px" }}
                  >
                    <source src={video3} type="video/mp4" />
                  </video>
                </div>
                <div class="carousel-item">
                  <video
                    autoPlay
                    muted
                    loop
                    class="d-block w-100"
                    style={{ height: "600px" }}
                  >
                    <source src={video4} type="video/mp4" />
                  </video>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-target="#carouselExampleControls"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-target="#carouselExampleControls"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <section class="pricing" id="pricing">
        <div class="container" data-aos="fade-in">
          <div class="section-title1 mt-5">
            <h3
              style={{ fontSize: "45px", fontWeight: "600", color: "#37517e" }}
              className="text-center"
            >
              Pricing
            </h3>
            <div class="section-title">
              <h2>Washing</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div class="box">
                <h3>Top Wash</h3>

                <ul>
                  <li>
                    <i class="bx bx-check"></i> Water and Dried with cloth
                  </li>

                  <li class="na">
                    <i class="bx bx-x"></i> <span>Foam wash , Vaccum</span>
                  </li>
                  <li class="na">
                    <i class="bx bx-x"></i>{" "}
                    <span>Interior polish, Fibre polish , Tyre ploish</span>
                  </li>
                  <li class="na">
                    <i class="bx bx-x"></i> <span>Engine Cleaning</span>
                  </li>
                </ul>
                <div className="col-6">
                  <a class="buy-btn">Rs.399 for all</a>
                </div>
              </div>
            </div>

            <div
              class="col-lg-6 mt-4 mt-lg-0"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="box featured">
                <h3>Premium Wash</h3>

                <ul>
                  <li>
                    <i class="bx bx-check"></i> Water and Dried with cloth
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Foam wash , Vaccum
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Interior polish, Fibre polish ,
                    Tyre ploish
                  </li>
                  <li>
                    <i class="bx bx-check"></i>Engine Cleaning
                  </li>
                </ul>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.499 for Hatchback</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.599 for Sedan</a>
                  </div>
                </div>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.699 for SUV</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.899 for Luxury</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-title">
            <h2 className="mt-5">Detailing</h2>
          </div>
          <div className="row">
            <div
              class="col-lg-6 mt-4 mt-lg-0"
              data-aos="fade-in"
              data-aos-delay="200"
            >
              <div class="box">
                <h3>Gold Package</h3>

                <ul>
                  <li>
                    <i class="bx bx-check"></i>Premium Wash
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Interior dry clean with 3mg wax
                  </li>
                  <li class="na">
                    <i class="bx bx-check"></i> <span>Body Shining</span>
                  </li>
                  <li class="na">
                    <i class="bx bx-check"></i>
                    <span>
                      Teflon Coating with 1 year gurantee(2 Premium Wash)
                    </span>
                  </li>
                  <li class="na">
                    <i class="bx bx-check"></i>
                    <span>
                      {" "}
                      Ceramic Coating with 3 year gurantee(6 Premium Wash)
                    </span>
                  </li>
                </ul>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.1499 for Hatchback</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.1999 for Sedan</a>
                  </div>
                </div>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.2499 for SUV</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.2999 for Luxury</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 mt-4 mt-lg-0"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="box">
                <h3>Platinum Package</h3>

                <ul>
                  <li>
                    <i class="bx bx-check"></i>Premium Wash
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Interior dry clean with 3mg wax
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Body Shining(Rubbing)
                  </li>
                  <li class="na">
                    <i class="bx bx-check"></i>
                    <span>
                      Teflon Coating with 1 year gurantee(2 Premium Wash)
                    </span>
                  </li>
                  <li class="na">
                    <i class="bx bx-check"></i>
                    <span>
                      {" "}
                      Ceramic Coating with 3 year gurantee(6 Premium Wash)
                    </span>
                  </li>
                </ul>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.2499 for Hatchback</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.3499 for Sedan</a>
                  </div>
                </div>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.3999 for SUV</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.4999 for Luxury</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 mt-4 mt-lg-0 pt-5"
              data-aos="fade-in"
              data-aos-delay="200"
            >
              <div class="box">
                <h3>Diamond Package</h3>

                <ul>
                  <li>
                    <i class="bx bx-check"></i>Premium Wash
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Interior dry clean with 3mg wax
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Body Shining(Rubbing)
                  </li>
                  <li>
                    <i class="bx bx-check"></i>
                    Teflon Coating with 1 year gurantee(2 Premium Wash)
                  </li>
                  <li class="na">
                    <i class="bx bx-check"></i>
                    <span>
                      {" "}
                      Ceramic Coating with 3 year gurantee(6 Premium Wash)
                    </span>
                  </li>
                </ul>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.4999 for Hatchback</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.5999 for Sedan</a>
                  </div>
                </div>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.7999 for SUV</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.9999 for Luxury</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 mt-4 mt-lg-0 pt-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="box featured">
                <h3>Blue Diamond Package</h3>

                <ul>
                  <li>
                    <i class="bx bx-check"></i>Premium Wash
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Interior dry clean with 3mg wax
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Body Shining(Rubbing)
                  </li>
                  <li>
                    <i class="bx bx-check"></i>
                    Teflon Coating with 1 year gurantee(2 Premium Wash)
                  </li>
                  <li>
                    <i class="bx bx-check"></i>
                    Ceramic Coating with 3 year gurantee(6 Premium Wash)
                  </li>
                </ul>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.10999 for Hatchback</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.14999 for Sedan</a>
                  </div>
                </div>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.17999 for SUV</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.24999 for Luxury</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-title">
            <h2 className="mt-5">Engine Service</h2>
          </div>
          <div className="row">
            <div
              class="col-lg-6 mt-4 mt-lg-0 mx-auto"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="box featured">
                <h3>Standard Package</h3>

                <ul>
                  <li>
                    <i class="bx bx-check"></i>Engine Service(Engine oil, Air
                    filter, Plug clean,Oil filter, Coolant, Break Pad, Engine
                    Cleaning, Light Check-up, Gate greasing, Wax, Alignment)
                  </li>
                  <li>
                    <i class="bx bx-check"></i> Premium Wash
                  </li>
                </ul>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.3999 for Hatchback</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.4999 for Sedan</a>
                  </div>
                </div>
                <div className="row p-0 m-0">
                  {" "}
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.6999 for SUV</a>
                  </div>
                  <div className="col-12 col-md-6">
                    {" "}
                    <a class="buy-btn">Rs.4999 for Luxury</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              *Denting and painting for all cars at just{" "}
              <span style={{ fontWeight: "800" }}>Rs.2499</span> except Luxury
              Cars
            </div>
          </div>
        </div>
      </section>

      <section id="testimonials" class="testimonials mt-5">
        <div class="container" data-aos="fade-in">
          <div class="section-title1 mb-5">
            <h3
              style={{ fontSize: "45px", fontWeight: "600", color: "white" }}
              className="text-center"
            >
              Testimonials
            </h3>
          </div>
          <div
            class="accordion d-flex justify-content-center align-items-center height"
            id="accordionExample"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="p-3">
                  <ul class="testimonial-list">
                    <li>
                      <div
                        class="card p-3"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        style={{
                          background: "transparent",
                          color: "white",
                        }}
                      >
                        <div
                          class="d-flex flex-row align-items-center"
                          data-aos="fade-in"
                        >
                          {" "}
                          <img
                            src={person1}
                            width="50"
                            height="50"
                            class="rounded-circle"
                            alt="testimonial"
                          />
                          <div class="d-flex flex-column ml-2">
                            {" "}
                            <span class="font-weight-normal">
                              Happy Customer
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        class="card p-3"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        style={{ background: "transparent", color: "white" }}
                      >
                        <div
                          class="d-flex flex-row align-items-center"
                          data-aos="fade-in"
                        >
                          {" "}
                          <img
                            src={person2}
                            width="50"
                            height="50"
                            class="rounded-circle"
                            alt="testimonial"
                          />
                          <div class="d-flex flex-column ml-2">
                            {" "}
                            <span class="font-weight-normal">
                              Happy Customer
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        class="card p-3"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        style={{ background: "transparent", color: "white" }}
                      >
                        <div
                          class="d-flex flex-row align-items-center"
                          data-aos="fade-in"
                        >
                          {" "}
                          <img
                            src={person4}
                            width="50"
                            height="50"
                            class="rounded-circle"
                            alt="testimonial"
                          />
                          <div class="d-flex flex-column ml-2">
                            {" "}
                            <span class="font-weight-normal">
                              Happy Customer
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div
                        class="card p-3"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                        style={{ background: "transparent", color: "white" }}
                      >
                        <div
                          class="d-flex flex-row align-items-center"
                          data-aos="fade-in"
                        >
                          {" "}
                          <img
                            src={person3}
                            width="50"
                            height="50"
                            class="rounded-circle"
                            alt="testimonial"
                          />
                          <div class="d-flex flex-column ml-2">
                            {" "}
                            <span class="font-weight-normal">
                              Happy Customer
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div class="p-3 testimonials-margin">
                  <div
                    id="collapseOne"
                    class="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                    style={{ color: "white" }}
                  >
                    <div class="card-body" data-aos="fade-down">
                      <h4>It was a great experience</h4>
                      <div class="ratings">
                        {" "}
                        <i class="fa fa-star"></i> <i class="fa fa-star"></i>{" "}
                        <i class="fa fa-star"></i>{" "}
                      </div>
                      <p>
                        I have been washing my car at Detailing Car Parlour for
                        a few months now and I’ve never had any problems. Every
                        time I go, all the employees are very nice and car comes
                        out even nice. I highly recommend their carwash :)
                      </p>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                    style={{ color: "white" }}
                  >
                    <div class="card-body">
                      <h4>Thanks for this great service</h4>
                      <div class="ratings">
                        {" "}
                        <i class="fa fa-star"></i> <i class="fa fa-star"></i>{" "}
                        <i class="fa fa-star"></i> <i class="fa fa-star"></i>{" "}
                        <i class="fa fa-star"></i>{" "}
                      </div>
                      <p>
                        I highly recommend Detailing Car Parlour! I had my van
                        sitting at another mechanic for a week. I finally went
                        and got my van and took it to Detailing Car Parlour on
                        Tuesday afternoon, and they said that they could work on
                        Wednesday. By Wednesday afternoon I had my van back and
                        in great working condition!
                      </p>
                    </div>
                  </div>
                  <div
                    id="collapseFour"
                    class="collapse show"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                    style={{ color: "white" }}
                  >
                    <div class="card-body" data-aos="fade-down">
                      <h4>The Best Shop in Town</h4>
                      <div class="ratings">
                        {" "}
                        <i class="fa fa-star"></i> <i class="fa fa-star"></i>{" "}
                        <i class="fa fa-star"></i>{" "}
                      </div>
                      <p>
                        This Car wash is awesome! I've been here many times, and
                        it’s the best Car Wash in Delhi/NCR. I do recommend this
                        car wash to all my family and friends. Cool PLACE!!
                      </p>
                    </div>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                    style={{ color: "white" }}
                  >
                    <div class="card-body">
                      <h4>You all are awesome thanks alot</h4>
                      <div class="ratings">
                        {" "}
                        <i class="fa fa-star"></i> <i class="fa fa-star"></i>{" "}
                        <i class="fa fa-star"></i> <i class="fa fa-star"></i>{" "}
                      </div>
                      <p>
                        The service was clear and understanding to me. Detailing
                        Car Parlour broke down each service my car was to
                        receive And took my Jeep in right away. I will
                        definitely recommend my family and friends to go .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" class="contact pb-5">
        <div class="container" data-aos="fade-up">
          <div class="section-title1">
            <h2
              className="py-5 text-center"
              style={{ fontSize: "45px", fontWeight: "600", color: "#37517e" }}
            >
              Contact
            </h2>
          </div>

          <div class="row">
            <div class="col-lg-5 d-flex align-items-stretch">
              <div class="info" data-aos="fade-in">
                <div class="address">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-geo-alt"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                  </i>
                  <h4>Location:</h4>
                  <p>Plot No.10, Ahinsa Khand-2,Indirapuram. Ghaziabad (U.P)</p>
                </div>

                <div class="email">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                    </svg>
                  </i>
                  <h4>Email:</h4>
                  <p>rahul@detailingcarparlour.com</p>
                </div>

                <div class="phone">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-telephone"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                  </i>
                  <h4>Call:</h4>
                  <p style={{ paddingBottom: "0" }}>+91 93130 07856</p>
                  <p style={{ paddingBottom: "0" }}>+91 70420 82790</p>
                  <p style={{ paddingBottom: "0" }}>+91 70420 82791</p>
                </div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.566964048997!2d77.37682651505082!3d28.642737890369904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf100db6ac3e3%3A0x5f7910f688afdbee!2sdetailingCarParlour!5e0!3m2!1sen!2sin!4v1635621496319!5m2!1sen!2sin"
                  frameborder="0"
                  style={{ border: "0", width: "100%", height: "290px" }}
                  allowfullscreen
                  title="map"
                ></iframe>
              </div>
            </div>

            <div
              class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch"
              data-aos="fade-in"
            >
              <form
                action="https://formspree.io/f/xgerjvbj"
                method="POST"
                class="php-email-form"
              >
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="name">Your Name</label>
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      id="name"
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="name">Your Email</label>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="name">Subject</label>
                  <input
                    type="text"
                    class="form-control"
                    name="subject"
                    id="subject"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="name">Message/Query</label>
                  <textarea
                    class="form-control"
                    name="message"
                    rows="10"
                    required
                  ></textarea>
                </div>
                <div class="my-3">
                  <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <footer id="footer" data-aos="fade-in">
        <div class="footer-top">
          <div class="container-fluid px-5">
            <div class="row">
              <div class="col-lg-3 col-md-6 footer-contact">
                <h3>Detailing Car Parlour</h3>
                <p>
                  Plot No.10, <br />
                  Ahinsa Khand-2,Indirapuram.
                  <br />
                  Ghaziabad (U.P) <br />
                  <br />
                  <strong>Phone:</strong> +91 9313007856
                  <br />
                  <strong>Phone:</strong> +91 7042082790 <br />
                  <strong>Phone:</strong> +91 7042082791
                  <br />
                </p>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a href="/">Home</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#about">About us</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#work">Our Work</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#testimonial">Testimonials</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#pricing">Pricing</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Car Washing</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Engine Service</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a>Denting & Painting</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i> <a>Car Designing</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>

                <div class="social-links mt-3">
                  <a
                    href="https://www.facebook.com/Detailing-Car-Parlour-114101050871786"
                    class="facebook"
                  >
                    <i>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-facebook"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                      </svg>
                    </i>
                  </a>
                  <a
                    href="https://www.instagram.com/detailingcarparlour/"
                    class="instagram"
                  >
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-instagram"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="container footer-bottom clearfix"
          style={{ width: "fit-content" }}
        >
          <div class="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Detailing Car Parlour</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
